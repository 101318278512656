import React, { useState } from "react";
import { Navbar, Nav, Button, Modal } from "react-bootstrap";

import JSignOut from "./JSignOut";

import "bootstrap/dist/css/bootstrap.min.css";
import FacialRecognitionContainer from "../FacialRecognition/FacialRecognitionContainer";
import SupplyChainContainer from "../SupplyChain/SupplyChainContainer";
// import UploadFiles from "../services/UploadFiles";

function CustomNavbar({ username }) {
    const [show, setShow] = useState(false);
    const [supplyshow, setSupplyShow] = useState(false);
    const [darkshow, setDarkShow] = useState(false);
    const [fileshow, setFileShow] = useState(false);
    return (
        <div>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand href="#home"></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto"    >
                </Nav>
                    <Nav>
                        <Navbar.Text
                            className="welcome-text d-none d-sm-block"
                            href="#">
                            Welcome {username}
                        </Navbar.Text>
                        <JSignOut />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default CustomNavbar;
